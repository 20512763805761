// import { data } from "jquery";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import HomeNav from "./HomeNav";
import TrackShipment from "./Tracking";
import axios from "axios";

const PincodeDetail = () => {
  const [pincode, setPinCode] = useState([]);
  const {  pincode_No } = useParams();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  // const [pincodeloading, setPincodeLoading] = useState(true);

  useEffect(() => {
    const FetchPincode = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_PINCODE_API_URL}/${pincode_No}`
        );
        // console.log("pincode Details", response.data);
        setPinCode(response.data.data);
        setLoading(false);
      } catch (response) {
        console.log(` Network Error fetching pincode data: ${response.status}`);
        setError(` Network Error fetching pincode data ${pincode_No} `);
        setPinCode([]);
        setLoading(false);
      }
    };
    FetchPincode();
  }, [pincode_No]);

  return (
    <>
      <Helmet>
        <title>Pincode Serviceability</title>
      </Helmet>
      <HomeNav position="relative" />

      {loading ? (
        <div>
          {/* <HomeNav position="absolute" topValue="inherit" /> */}
          <div className="container mt-5">
            <div className="row justify-content-center">
              <div className="col-md-5 mt-5">
                <Skeleton count={5} />
              </div>
            </div>
          </div>
        </div>
      ) : pincode.length === 0 ? (
        <>
          <div className="tracking-section">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 step-part">
                  <p className="text-white primum-steps">
                    <h2 className="text-white">
                    Pincode Serviceability for: <b>{pincode_No} </b>
                    </h2>
                  </p>
                </div>
                <div className="col-lg-1"></div>
                <div className="col-lg-5">
                  <div className="mt-5">
                    <TrackShipment />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-5">
              <img
                src="/image/pincodeNotFound.png"
                alt=""
                srcSet=""
                className="w-100 mt-5"
              />
            </div>
          </div>
        </>
      ) : (
        <div>
          {pincode.length > 0 && (
            <div>
              <div className="tracking-section">
                <div className="container-fluid">
                  <div className="row ">
                    <div className="col-lg-7 step-part">
                      <div className="tracking-header primum-steps">
                        <h2 className="text-white">
                          Pincode Serviceability for : <b>{pincode_No} </b>
                        </h2>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div className="">
                        <TrackShipment />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                {pincode.map((item, index) => (
                  <div key={index} className="wrapper_pinocode">
                    <div className="pinoce_flex_wrapper">
                      <div className=" text-center pincode_courrier_warraper">
                        <img
                          src={item.image}
                          alt=""
                          className="pincode_courrier_image"
                        />
                        <p> {item.title}</p>
                      </div>
                      <div className="pincode_center">
                        <p>Center</p>
                        <h6>{item.center}</h6>
                      </div>

                      <div className="Serviceability_icon">
                        <p>Serviceability</p>
                        <h6>
                          {item.status === "Yes" ? (
                            <i className="fa-solid fa-check"></i>
                          ) : item.status === "No" ? (
                            <i className="fa-solid fa-xmark"></i>
                          ) : (
                            "Invalid Value"()
                          )}
                        </h6>
                      </div>

                      <div className="oda_icon">
                        <p>ODA</p>

                        <h6>
                          {item.oda === "No" ? (
                            <i className="fa-solid fa-xmark"></i>
                          ) : item.oda === "Yes" ? (
                            <i className="fa-solid fa-check"></i>
                          ) : (
                            "Invalid Value"
                          )}
                        </h6>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PincodeDetail;
