import React from "react";
import Skeleton from "react-loading-skeleton";

const CalculatorLoader = () => {
  return (
    <>
      <div>
        <div className="row">
          {Array.from({ length: 8}).map((_, index) => (
            <div key={index} className="col-lg-6">
              <div className="rate_skelton">
                <div className="skelton_image">
                  <div>
                    <Skeleton
                      width={50}
                      height={50}
                      style={{ margin: "10px 0px", borderRadius: "50%" }}
                    />
                  </div>
                  <div>
                    <Skeleton
                      width={120}
                      height={10}
                      style={{ margin: "10px 10px" }}
                    />

                    <Skeleton height={30} />
                  </div>
                  <Skeleton
                    width={90}
                    height={10}
                    style={{ margin: "5px 10px" }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CalculatorLoader;
