import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import TrackShipment from "../components/Tracking";
import HomeNav from "../components/HomeNav";
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import { Helmet } from "react-helmet";

const Particular = () => {
  const bg_pattern = {
    backgroundImage: ` url("/image/tracking-bg.png")`,
    backgroundSize: "cover",

    backgroundRepeat: "no-repeat",
  };
  const [shipTrack, setShipTrack] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { trackingNo } = useParams();
  // console.log("ID:", trackingNo);

  useEffect(() => {
    const fetchshipTrack = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_TRACKING_API_URL}/${trackingNo}`
        );
        setShipTrack(response.data.data);
        setError(null);
        setLoading(false);

        console.log("API response", response.data.data);
      } catch (error) {
        setError(
          `Tracking History is not available for this ${trackingNo}  . Please try sometime`
        );
        setLoading(false);
        setShipTrack(null);
      }
    };
    fetchshipTrack();
  }, [trackingNo]);

  if (loading) {
    return (
      <>
        <div className="container mt-5">
          <div className="row justify-content-center">
            <div className="col-md-5">
              <Skeleton count={5} />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Order Track</title>
      </Helmet>
      <HomeNav position="relative" />

      {error ? (
        <div className="tracking-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 step-part">
                <p className="text-white primum-steps">
                  <h2 className="text-white">
                    Tracking Number: <b>{trackingNo} </b>
                  </h2>
                </p>
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-5">
                <div className="">
                  <TrackShipment />{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {error ===
        `Tracking History is not available for this ${trackingNo}  . Please try sometime` && (
        <div className="text-center mt-5">
          <img
            src="/image/TrackingNumberNotFound.png"
            alt=""
            srcset=""
            className="pincodeNotFound"
          />
        </div>
      )}
      {shipTrack && (
        <div>
          <div className="tracking-section">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-7 col-md-12 step-part">
                  <div className="tracking-header primum-steps ">
                    <div className="d-flex gap-5 align-items-center">
                      <div className="courier_tacking_image">
                        <img
                          src={shipTrack.image}
                          alt="Carrier Logo"
                          srcSet=""
                          className="w-100"
                        />
                      </div>
                      <p className="text-white">
                        Tracking Number <br />
                        <h3 className="text-white">
                          <b>{shipTrack.tracking_no}</b>
                        </h3>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-12">
                  <div className="">
                    <TrackShipment />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mt-2">
              <div className="col-lg-12">
                <div class="breadcrumb flat">
                  {shipTrack.status === "Not Picked" ? (
                    <Link
                      to="javascript:void(0)"
                      className={`${
                        shipTrack.status === "Not Picked"
                          ? "activenotpicked"
                          : ""
                      }`}
                    >
                      Not Picked
                    </Link>
                  ) : null}
                  <Link
                    to="javascript:void(0)"
                    className={`${
                      shipTrack.status === "Manifested"
                        ? "activeManifested"
                        : ""
                    }`}
                  >
                    Manifiest
                  </Link>

                  <Link
                    to="javascript:void(0)"
                    className={`${
                      shipTrack.status === "In Transit" ? "activeTransit" : ""
                    }`}
                  >
                    In Transit
                  </Link>
                  <Link
                    to="javascript:void(0)"
                    className={`${
                      shipTrack.status === "Pending" ? "activePending" : ""
                    }`}
                  >
                    Pending
                  </Link>
                  <Link
                    to="javascript:void(0)"
                    className={`${
                      shipTrack.status === "Dispatched"
                        ? "activeDispatched"
                        : ""
                    }`}
                  >
                    Dispatched
                  </Link>
                  <Link
                    to="javascript:void(0)"
                    className={`${
                      shipTrack.status === "Delivered" ? "activeDelivered" : ""
                    }`}
                  >
                    Delivered
                  </Link>
                  {/* Show RTO link conditionally if the status is RTO then show RTO Link otherwise hide the link */}
                  {shipTrack.status === "RTO" ? (
                    <Link
                      to="javascript:void(0)"
                      className={`${
                        shipTrack.status === "RTO" ? "activeRTO" : ""
                      }`}
                    >
                      RTO
                    </Link>
                  ) : null}
                  {shipTrack.status === "LOST" ? (
                    <Link
                      to="javascript:void(0)"
                      className={`${
                        shipTrack.status === "LOST" ? "activeLost" : ""
                      }`}
                    >
                      Lost
                    </Link>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-lg-6 col-md-12 col-12 orderfirst">
                <div className="order-details show-scrollbar scan_tracking">
                  {shipTrack.scaninfo.map((scan, index) => (
                    <div key={index}>
                      <div className="scan_details">
                        <div className="check_icon">
                          <div
                            className={`tracking_icon ${scan.status} ${
                              scan.status !== shipTrack.status
                                ? "mismatched_status"
                                : ""
                            }`}
                          >
                            <i class="fa-solid fa-location-dot"></i>
                          </div>
                        </div>
                        <div
                          className="tracking_action"
                          style={{ width: "70%" }}
                        >
                          <div
                            className={`status_scan ${scan.status} ${
                              scan.status !== shipTrack.status
                                ? "mismatched_status"
                                : ""
                            }`}
                          >
                            {" "}
                            {/* <p>{formatText(scan.status)}</p> */}
                            <h5>{scan.status} </h5>
                          </div>
                          <p>{scan.remark}</p>
                          <p>{scan.location}</p>
                          <p className="tracking_date_time">{scan.date}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-lg-1 mb-4"></div>
              <div className="col-lg-5 col-md-12 col-12 mb-4 ordersecond">
                <div
                  className="order-details tracking-orders"
                  style={bg_pattern}
                >
                  <div className="display_flex_details">
                    {shipTrack.masterNo ? (
                      <div className="pull-left">
                        <p>
                          <h3>
                            <b>{shipTrack.tracking_no}</b>
                          </h3>
                        </p>
                      </div>
                    ) : (
                      <p>
                        <h4>
                          <b>{shipTrack.tracking_no}</b>
                        </h4>
                      </p>
                    )}
                  </div>

                  <br />

                  <div className="display_flex_details">
                    <div className="pull-left" style={{ width: "60%" }}>
                      {shipTrack.masterNo ? (
                        <p>
                          <h6>Master Number</h6> <h5>{shipTrack.masterNo}</h5>
                        </p>
                      ) : null}

                      <p>
                        <h6>Order Placed On</h6>{" "}
                        <h5>
                          {format(
                            new Date(shipTrack.manifestDate),
                            "MMMM dd, yyyy"
                          )}
                        </h5>
                      </p>
                      <p>
                        <h6>Payment Mode</h6> <h5>{shipTrack.mode}</h5>
                      </p>

                      <p>
                        <h6>No. of Boxes</h6> <h5>{shipTrack.boxCount}</h5>
                      </p>

                      {shipTrack.status === "Delivered" ||
                      shipTrack.status === "RTO" ? (
                        <p>
                          {shipTrack.deliveredDate === "" ? null : (
                            <h6>Delivered Date of Delivery</h6>
                          )}{" "}
                          <h5> {shipTrack.deliveredDate}</h5>
                        </p>
                      ) : (
                        <p>
                          <h6>Expected Date of Delivery</h6>{" "}
                          <h5> {shipTrack.promisedDate}</h5>
                        </p>
                      )}

                      <p>
                        <h6>Receiver</h6>
                        <h5>
                          {shipTrack.consignee.name}
                          <br />
                          {shipTrack.consignee.address}
                          <br />
                          {shipTrack.consignee.city}
                          <br />
                          {shipTrack.consignee.state}
                          <br />
                          {shipTrack.consignee.pincode}
                        </h5>
                      </p>
                    </div>
                    <div className="pull-left" style={{ width: "40%" }}>
                      <p>
                        <h6>Order ID</h6> <h5>{shipTrack.orderId}</h5>
                      </p>
                      <p>
                        <h6>Status</h6> <h5>{shipTrack.status}</h5>
                      </p>
                      {shipTrack.pickupDate && (
                        <p>
                          <h6>Pickup Date</h6> <h5>{shipTrack.pickupDate}</h5>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Particular;
