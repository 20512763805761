import Aos from "aos";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
// import { Alert, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Lottie from 'lottie-react';

import success from '../assets/images/TFS.json'; 
import { Button } from "react-bootstrap";

const NewsLetter = ({ background, content, paragraph }) => {
  const newsletterStyle = {
    background,
    content,
  };

  const [showSuccessAlert, setShowSuccessAlert] = useState(false); // New state for success alert
  const [error, setError] = useState(null);

  const [emailID, setEmailID] = useState("");

  useEffect(() => {
    emailValidation(emailID);
  }, [emailID]);

  const emailValidation = (emailID) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (emailID.trim() === "") {
      setError(null);
    } else if (!emailRegex.test(emailID)) {
      setError("Please Enter A Valid Email Address");
    } else {
      setError(null); // Clear any previous error messages
    }
  };

  const handleNewLetter = async (event) => {
    event.preventDefault();
    if (error) {
      return;
    }

    let url = "https://b2b.truxcargo.com/api/truxapi/newsletter";
    let body = {
      email: emailID,
    };
    try {
      const response = await axios.post(url, body);
      console.log(response.data);
      setShowSuccessAlert(true);
      setEmailID("");
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify(newletter),
      //   }
      // );

      // if (response.ok) {
      //   const responseData = await response.json();
      //   setShowSuccessAlert(true);
      //   console.log("API response:", responseData);
      // } else {
      //   console.log("API response not okay. Status:", response.status);
      //   const errorResponse = await response.text();
      //   console.log("API error response:", errorResponse);
      // }
    } catch (error) {
      console.error("Network error:", error);
      setError("Network error: " + error.message);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default form submission behavior (refresh)
      handleNewLetter(); // Call the same function as the track button click
    }
  };

  useEffect(()=>{
  const timeoutId = setTimeout(() => {
      setShowSuccessAlert(false)
    }, 3000);
    return ()=>clearTimeout(timeoutId)
  })

  // useEffect(() => {
  //   if (showSuccessAlert) {
  //     setEmailID({
  //       email: "",
  //     });
  //   }
  // }, [showSuccessAlert]);

  useEffect(() => {

    Aos.init();
  }, []);
  return (
    <>
      <section className="news_letter_section  margin_top_bottom">
        <div className="container-fluid">
          <div
            className="newsletterwrapper"
            style={newsletterStyle}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="newsletterinside">
              <h2>{content}</h2>
              <p>{paragraph}</p>
              <Form onSubmit={handleNewLetter}>



                {showSuccessAlert && (
                  <div>
                  <Lottie animationData={success}  autoplay loop={false} style={{ width: '300px', height: '300px', margin:"auto" }}/>
                </div>
                  // <Alert
                  //   variant="success"
                  //   onClose={() => setShowSuccessAlert(false)}
                  //   dismissible
                  // >
                  //   Thank you for subscribing!
                  // </Alert>
                )}
                <input
                  type="text"
                  placeholder="Enter Your Email Address"
                  className="newsletter_input "
                  name="email"
                  value={emailID}
                  onChange={(e) => setEmailID(e.target.value)}
                  onKeyPress={handleKeyPress}
                  required
                />
                <Button type="onsubmit" className="btn btn_newletter">
                  Subscribe
                </Button>
                <br /> <br />
                {error && <p className="error-message">{error}</p>}
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewsLetter;
