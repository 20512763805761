import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
const ModalHome = () => {
  const text = "WELCOMETRX";

  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (


    <>
    <div className={isSticky ? "discount sticky" : "modal_discount_open"}>
    <div className="modal_discount_open">
      <p>
        Ship your first order for <b>FREE</b> with us today | Use Code: <b><Link to="/shipment-coupons">WELCOMETRX</Link></b>
        {/* <b>  {text}</b>{" "} */}
        {/* <span>
          <button
            className="button-14"
            role="button"
            onClick={() => {
              navigator.clipboard.writeText(text);
            }}
          >
            Copy Coupon!
          </button>
        </span>{" "} */}
      </p>
    </div>
    </div>
    </>
   
  );
};

export default ModalHome;
